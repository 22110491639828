import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Icons
import { Next, Prev } from "../icons/slideshow-icons";

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  padding: 60px 90px 35px 90px;

  @media (max-width: 1250px) {
    padding: 60px 40px 35px 40px;
  }

  @media (max-width: 768px) {
    padding: 50px 0 35px 0;
  }

  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .image-gallery,
  & .slider,
  & .slide,
  & video,
  & img {
    height: 100%;
  }

  & .react-multi-carousel-list {
    max-width: 1200px;
    margin: 0 auto;
  }

  & button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    z-index: 100;
    border: 0;

    &.carousel__back-button {
      left: 0;
    }
    &.carousel__next-button {
      right: 0;
    }
  }
`;

const CarouselButton = styled.button`
  position: absolute;
  padding: 0;

  &.custom-left-arrow {
    left: 0;
  }

  &.custom-right-arrow {
    right: 0;
  }

  @media (max-width: 699px) {
    &.custom-left-arrow {
      left: -10px;
    }

    &.custom-right-arrow {
      right: -10px;
    }

    & svg {
      width: 15px;
      height: auto;
    }
  }

  @media (max-width: 400px) {
    & svg {
      width: 10px;
      height: auto;
    }
  }
`;

export const AvailableSoonGallery = ({ galleryListings }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [allGalleryContent, setAllGalleryContent] = useState([]);
  const [totalSlides, setTotalSlides] = useState(null);

  useEffect(() => {
    setTotalSlides(galleryListings.length);
    setAllGalleryContent(galleryListings);
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 699, min: 0 },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group">
        {galleryListings.length >= 1 && (
          <>
            <CarouselButton
              onClick={() => previous()}
              className="custom-left-arrow"
            >
              <Prev fill={`#225F18`} />
            </CarouselButton>
            <CarouselButton
              onClick={() => next()}
              className="custom-right-arrow"
            >
              <Next fill={`#225F18`} />
            </CarouselButton>
          </>
        )}
      </div>
    );
  };

  return (
    <GalleryContainer>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        arrows={false}
        responsive={responsive}
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
        ssr={true}
        infinite={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        itemClass="carousel-item"
      >
        {galleryListings}
      </Carousel>
    </GalleryContainer>
  );
};
