import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import dayjs from "dayjs";

// Components
import { AvailableSoonGallery } from "../images/available-soon-gallery";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Layout
import { AvailableSoon, AvailableSoonListing } from "./listing-components";

export const ListingsAvailableSoon = ({ listings }) => {
  const listingsAvailableSoon = listings
    .filter(listing => listing.node.data.start_date !== null)
    .filter(listing => {
      const now = dayjs();
      const availabilityStartDate = dayjs(listing.node.data.start_date);
      const availableSoon = availabilityStartDate.diff(now, "day");

      if (availableSoon >= 1 && availableSoon <= 365) {
        return (listing.node = {
          ...listing.node,
          availability: availableSoon,
        });
      }
    })
    .sort((a, b) => a.node.availability - b.node.availability)
    .map((listing, index) => {
      const availabilityStartDate = dayjs(listing.node.data.start_date);
      const availabilityEndDate = dayjs(listing.node.data.end_date);
      const totalNights = availabilityEndDate.diff(
        availabilityStartDate,
        "day"
      );

      const availabilityStartDay = availabilityStartDate.format("D MMMM YYYY");

      const formattedPrice = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: listing.node.data.country.document.data.currency_code,
        maximumSignificantDigits: Math.trunc(
          Math.abs(listing.node.data.price)
        ).toFixed().length,
      }).format(listing.node.data.price);

      return (
        <div key={`available_soon_listing_${listing.node.id}`}>
          <AvailableSoonListing>
            <Link to={listing.node.url}>
              <AspectRatioImageContainer image={null} padding={66.667}>
                <img
                  src={listing.node.data.thumbnail.fluid.srcWebp}
                  srcSet={listing.node.data.thumbnail.fluid.srcSetWebp}
                  alt={listing.node.data.thumbnail.alt}
                  loading="lazy"
                />
              </AspectRatioImageContainer>
            </Link>
            <div className="listing-container">
              <h2 className="transcript">
                {listing.node.data.city.document !== null &&
                  listing.node.data.city.document.data.name.text}

                {listing.node.data.city.document === null &&
                  listing.node.data.fallback_city !== null &&
                  listing.node.data.fallback_city}
              </h2>

              <div>
                <p className="travel-time crimson">
                  <span className="dots">...</span>
                  <em>{listing.node.data.travel_time_from_city.text}</em>
                  <span className="dots">...</span>
                </p>
              </div>

              <div className="mobile-title">
                <p className="transcript">
                  <Link to={listing.node.url}>
                    {listing.node.data.title.text}
                  </Link>
                </p>
              </div>

              <div className="details">
                <p className="transcript desktop-title">
                  <Link to={listing.node.url}>
                    {listing.node.data.title.text}
                  </Link>
                </p>
                <p className="crimson">Sleeps {listing.node.data.sleeps}</p>
                <p className="crimson">
                  {totalNights} night{totalNights > 1 && `s`}, arrive{" "}
                  {availabilityStartDay}
                </p>
                <p className="crimson">{formattedPrice} total</p>
              </div>
            </div>
          </AvailableSoonListing>
        </div>
      );
    });

  if (listingsAvailableSoon.length >= 1) {
    return (
      <AvailableSoon>
        <h3 className="heading transcript">Places available soon</h3>
        <AvailableSoonGallery galleryListings={listingsAvailableSoon} />
      </AvailableSoon>
    );
  } else {
    return null;
  }
};
