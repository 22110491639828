/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * https://ourcodeworld.com/articles/read/764/how-to-sort-alphabetically-an-array-of-objects-by-key-in-javascript
 *
 * @param {String} property Key of the object to sort.
 */
export const dynamicSort = property => {
  var sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }

  if (property === "alphabetical") {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.node.data.title.text.localeCompare(a.node.data.title.text);
      } else {
        return a.node.data.title.text.localeCompare(b.node.data.title.text);
      }
    };
  }

  if (property === "price") {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.node.data.single_night_price - a.node.data.single_night_price;
      } else {
        return a.node.data.single_night_price - b.node.data.single_night_price;
      }
    };
  }

  if (property === "sleeps") {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.node.data.sleeps - a.node.data.sleeps;
      } else {
        return a.node.data.sleeps - b.node.data.sleeps;
      }
    };
  }

  if (property === "distance") {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.distance - a.distance;
      } else {
        return a.distance - b.distance;
      }
    };
  }
};
